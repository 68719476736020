import React, { Component } from 'react';
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import {
  FaFontAwesomeFlag,
  FaGavel,
  FaGraduationCap,
  FaBook,
  FaMusic,
  FaCompass,
  FaUniversity,
  FaPencilAlt,
  FaCalendarPlus,
  FaFileAlt,
  FaPrayingHands,
  FaLightbulb,
  FaExternalLinkAlt,
} from 'react-icons/fa';
import Layout from '../../components/Layout';
import PageHeading from '../../components/PageHeading/PageHeading';
import LogoImg from '../../img/logobanner-1024x80.png';
import styles from '../../css/resources.module.css';

class Resources extends Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="VEYM | Resources"
          meta={[
            {
              name: 'description',
              content:
                'TNTT Resources - Bylaws, Ceremony Manual, Official Songs, Specialized Skills, Training Regulations, Training Resources, VEYM Curricula, Weekly Gospel Lessons, Forms',
            },
          ]}
        />
        <PageHeading>Resources</PageHeading>
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Resources</BreadcrumbItem>
          </Breadcrumb>
        </Container>
        <Container>
          <img className="img-fluid" src={LogoImg} width="100%" alt="khan" />
        </Container>
        <br />
        <Container className={styles.resources}>
          <ListGroup>
            <ListGroupItem>
              <ListGroupItemHeading>
                <Link to="/resources/bylaws">
                  <FaGavel />
                  &nbsp; Bylaws
                </Link>
              </ListGroupItemHeading>
              <ListGroupItemText>Nội Quy</ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>
                <Link to="/resources/ceremonymanual">
                  <FaFontAwesomeFlag />
                  &nbsp; Ceremony Manual
                </Link>
              </ListGroupItemHeading>
              <ListGroupItemText>Nghi Thức</ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>
                <Link to="/resources/trainingregulations">
                  <FaGraduationCap />
                  &nbsp; Training Regulations
                </Link>
              </ListGroupItemHeading>
              <ListGroupItemText>Quy Chế Huấn Luyện</ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>
                <Link to="/resources/trainingresources">
                  <FaBook />
                  &nbsp; Training Resources
                </Link>
              </ListGroupItemHeading>
              <ListGroupItemText>Tài Liệu Huấn Luyện</ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>
                <Link to="/resources/officialsongs">
                  <FaMusic />
                  &nbsp; Official Songs
                </Link>
              </ListGroupItemHeading>
              <ListGroupItemText>Ca Chính Thức</ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>
                <Link to="/resources/prayers">
                  <FaPrayingHands />
                  &nbsp; Prayers
                </Link>
              </ListGroupItemHeading>
              <ListGroupItemText>Kinh Nguyện</ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>
                <Link to="/resources/specializedskills">
                  <FaCompass />
                  &nbsp; Specialized Skills
                </Link>
              </ListGroupItemHeading>
              <ListGroupItemText>Chuyên Môn</ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>
                <Link to="/resources/curricula">
                  <FaUniversity />
                  &nbsp; VEYM Curricula
                </Link>
              </ListGroupItemHeading>
              <ListGroupItemText>Chương Trình Thăng Tiến</ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>
                <Link to="/asap">
                  <FaPencilAlt />
                  &nbsp; Assessment of Student Academic Performance (ASAP)
                </Link>
              </ListGroupItemHeading>
              <ListGroupItemText>Benchmark Assessment</ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>
                <Link to="/resources/weeklygospel">
                  <FaCalendarPlus />
                  &nbsp; Weekly Gospel Lessons
                </Link>
              </ListGroupItemHeading>
              <ListGroupItemText>Weekly Gospel Lessons</ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>
                <Link to="/resources/forms">
                  <FaFileAlt />
                  &nbsp; Forms
                </Link>
              </ListGroupItemHeading>
              <ListGroupItemText>Các Mẫu Đơn</ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>
                <a
                  href="https://educenter.veym.net"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLightbulb />
                  &nbsp; Education Center &nbsp; <FaExternalLinkAlt />
                </a>
              </ListGroupItemHeading>
              <ListGroupItemText>
                Portal for training and education courses
              </ListGroupItemText>
            </ListGroupItem>
          </ListGroup>
        </Container>
        <br />
        <br />
      </Layout>
    );
  }
}

export default Resources;
